.c-btn {
  --button-background: var(--color-blue-500);
  --button-color: white;
  @extend .t-base-font;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: $size-md 12px;
  width: 100%;
  // max-height: $size-3xl;
  color: var(--button-color);
  font-size: $size-base;
  letter-spacing: 1px;
  text-align: center;
  text-decoration: none;
  background: var(--button-background);
  border-radius: $size-sm;
  border: 1px solid transparent;
  white-space: nowrap;
  appearance: none;
  cursor: pointer;

  &--auto-width {
    width: auto;
  }

  &--lg {
    font-size: 1.125 * $size-base;
  }

  &--sm {
    padding: $size-md 12px !important;
    font-size: $size-base !important;
  }

  &__icon {
    height: $size-lg;
    width: $size-lg;
    font-size: 1.2em;
    padding: 0;
    align-items: center;
    justify-content: center;
    color: var(--button-color);

    &--desktop-only {
      display: none;
    }
  }

  &--primary {
    --button-background: var(--color-blue-500);
    --button-color: var(--color-white);
  }

  &--secondary {
    --button-background: var(--color-blue-100);
    --button-color: var(--color-black);
  }

  &--tertiary {
    --button-background: var(--color-white);
    --button-color: var(--color-blue-500);
    border: 1px solid var(--color-blue-500);
  }

  &--orange {
    --button-background: var(--color-orange-200);
    --button-color: var(--color-white);
  }

  &--untoggled {
    --button-background: var(--color-blue-300);
    --button-color: var(--color-blue-700);
  }

  &--gray {
    --button-background: var(--color-gray-200);
    --button-color: var(--color-blue-700);
  }

  &--clear {
    --button-background: transparent;
    --button-color: var(--color-blue-500);
    border: none;

    &:hover {
      opacity: 0.7 !important;
      box-shadow: unset !important;
    }
  }

  &--disabled {
    --button-background: var(--color-gray-300);
    --button-color: var(--color-gray-600);
    cursor: not-allowed;
  }

  &:hover {
    opacity: 0.95;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
  }

  &__text {
    max-height: $size-lg;
  }

  &__loading {
    height: $size-lg;
    width: $size-lg;
    &--untoggled {
      --button-background: var(--color-blue-300);
      --button-color: var(--color-blue-700);

      .c-spinner {
        border-top: 2px solid var(--color-blue-700);
      }
    }
  }

  span:empty {
    display: none;
  }

  span:empty {
    display: none;
  }

  @media screen and (min-width: $break-md) {
    padding: $size-md;
    font-size: 1.25 * $size-base;
    height: 100%;
    max-height: 58px;

    &--sm {
      max-height: $size-3xl;
    }

    &__icon--desktop-only {
      display: initial;
    }
  }

  &--icon-only {
    height: $size-2xl;
    padding: 0.5rem;
  }
}

:host {
  display: block;
}
