// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --color-gray-100: #f8f9fe;
  --color-gray-200: #e8e9f1;
  --color-gray-300: #d4d6dd;
  --color-gray-400: #c5c6cc;
  --color-gray-500: #8f9098;
  --color-gray-600: #71727a;
  --color-gray-700: #494a50;
  --color-gray-800: #2f3036;
  --color-gray-900: #111122;

  --color-white: #ffffff;
  --color-black: #1f2024;

  --color-blue-background: #f8f9fe;
  --color-blue-100: #f1f8ff;
  --color-blue-200: #e1f2fe;
  --color-blue-300: #c0e5fd;
  --color-blue-400: #64bbfb;
  --color-blue-500: #2f69b0;
  --color-blue-600: #1f4977;
  --color-blue-700: #112f4f;

  --color-orange-100: #f5d3a8;
  --color-orange-200: #e56a21;
  --color-orange-300: #902d11;

  --color-yellow-200: #d5cd1d;

  --color-text-dark: #334155;
  --color-text-medium: #94a3b8;
  --color-text-light: #f8fafc;

  --color-error: #ff5863;
  --color-warning: #f7c662;
  --color-success: #1b9a8f;
  --color-message: #007fff;

  --test-blue-3: #6fbaff;
  --test-blue-5: #006ffd;

  --support-yellow-1: #f2f8c9;
  --support-yellow-3: #7a6019;

  --support-green-1: #dcfbcc;
  --support-green-3: #256014;

  --support-blue-1: #dbf8ff;
  --support-blue-3: #154a64;

  --support-purple-1: #eadcf8;
  --support-purple-3: #592d76;

  --support-red-1: #f4d6e1;
  --support-red-3: #722938;

  --shadow-md: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  --shadow-focus: drop-shadow(0 0 2px #0084ff);
}
