@import '../sizing.scss';
@import '../fonts.scss';
@import '../variables.scss';

.c-field {
  color: var(--color-black) !important;

  &__label {
    margin-bottom: $size-sm;
    font-size: 1.125rem !important;
    line-height: 1.5rem !important;
    color: inherit !important;

    &--disabled {
      color: var(--color-gray-500) !important;
    }

    &--invalid {
      color: var(--color-orange-300) !important;
    }
  }

  &_container {
    position: relative;
    display: flex;
    align-items: center;
  }

  &__input:focus {
    color: var(--color-blue-600) !important;
    border-color: var(--color-blue-600) !important;
  }

  &__input,
  &__select {
    display: block;
    width: 100%;
    padding: $size-base !important;
    min-height: unset !important;
    color: var(--color-black);
    background: var(--color-blue-100);
    border: 1px solid var(--color-blue-100);
    border-radius: 8px;

    &::placeholder {
      color: var(--color-gray-500);
    }

    &:disabled,
    &--disabled {
      background: var(--color-gray-100);
      pointer-events: none;
      cursor: not-allowed;

      &::placeholder {
        color: var(--color-gray-400);
      }
    }

    &--invalid {
      background: var(--color-orange-100);
      color: var(--color-orange-500) !important;

      &::placeholder {
        color: var(--color-orange-500);
      }
    }

    &--no-arrow {
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  &__icon {
    &-delete {
      position: absolute;
      right: 0;
      top: 50%;
      padding: $size-md;
      font-size: 1.3rem;
      color: var(--color-gray-700);
      transform: translateY(-50%);
      transition: all 195ms;
      cursor: pointer;

      &:hover {
        color: var(--color-blue-700);
      }

      &.c-field--number {
        right: 10px;
      }

      &.c-field--focused {
        color: var(--color-blue-700);
      }

      &.c-field--disabled {
        color: var(--color-gray-400);
        pointer-events: none;
      }
    }

    &__helper {
      margin-top: $size-sm;
      font-size: 0.875rem;
      color: var(--color-gray-600);
    }

    /*
    * The styles below prevent the autofill from changing the background and text color of the input.
    */
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-background-clip: text !important;
      -webkit-text-fill-color: var(--color) !important;
    }
  }

  &__profile-picture-container,
  &__logo-picture-container {
    position: relative;
  }

  &__profile-picture-icon,
  &__logo-picture-icon {
    position: absolute;
    bottom: 0;
    right: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    width: 32px;
    height: 32px;
    background-color: var(--color-blue-500);
    color: white;
    border-radius: 4px;

    &:hover {
      background-color: var(--color-blue-600);
    }
  }

  &__profile-picture,
  &__logo-picture {
    border: 1px solid var(--color-gray-400);
    border-radius: 8px;
    min-width: 120px;
    max-width: 120px;
    min-height: 50px;
    max-height: 120px;

    cursor: pointer;
  }

  input[type='date'] {
    --color-font: var(--color-black);
    color: transparent;
    position: relative;

    &:before {
      color: var(--color-black);
      position: absolute;
      content: attr(placeholder);
      width: calc(100% - 60px);
      background: var(--color-blue-100);
    }

    &.empty::before {
      color: var(--color-gray-500);
    }
  }
  &__logo-picture {
    max-width: 200px;
    max-height: 150px;
  }

  &__select {
    /* Arrow */
    appearance: none;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9ImNoZXZyb24tZG93bi1vdXRsaW5lIj4KPHBhdGggaWQ9IlZlY3RvciAoU3Ryb2tlKSIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zLjc4MzE2IDYuNTk1NjZDNC4xMDg2MiA2LjI3MDIgNC42MzYzIDYuMjcwMiA0Ljk2MTc2IDYuNTk1NjZMOS45OTc0NiAxMS42MzE0TDE1LjAzMzIgNi41OTU2NkMxNS4zNTg2IDYuMjcwMiAxNS44ODYzIDYuMjcwMiAxNi4yMTE4IDYuNTk1NjZDMTYuNTM3MiA2LjkyMTEyIDE2LjUzNzIgNy40NDg4IDE2LjIxMTggNy43NzQyNkwxMC41ODY4IDEzLjM5OTNDMTAuNDMwNSAxMy41NTU2IDEwLjIxODUgMTMuNjQzNCA5Ljk5NzQ2IDEzLjY0MzRDOS43NzY0MyAxMy42NDM0IDkuNTY0NDUgMTMuNTU1NiA5LjQwODE2IDEzLjM5OTNMMy43ODMxNiA3Ljc3NDI2QzMuNDU3NyA3LjQ0ODggMy40NTc3IDYuOTIxMTIgMy43ODMxNiA2LjU5NTY2WiIgZmlsbD0iIzhGOTA5OCIvPgo8L2c+Cjwvc3ZnPgo=');
    background-repeat: no-repeat;
    background-position: right 1rem top 50%;
    background-size: 20px;

    &--no-arrow {
      background-image: none;
      display: flex;
      justify-content: center;
    }
  }

  &__icon {
    position: absolute;
    z-index: 2;
    right: $size-md;
    color: var(--color-blue-700);
    font-size: 1.5 * $size-base;

    &--error {
      color: var(--color-orange-300) !important;
    }

    &--left {
      left: $size-md;
      right: auto;
    }
  }

  &__error {
    z-index: 2;
    display: inline-block;
    margin-top: $size-xs;
    color: var(--color-orange-300);
    font-size: 0.9 * $size-base;
  }

  @media screen and (min-width: $break-md) {
    &__label {
      font-size: 1.125 * $size-base;
    }

    &__input {
      font-size: $size-base;
    }
  }
}

.c-form-phone {
  background-color: var(--color-blue-100) !important;
  border-radius: 8px;
  color: var(--color-black) !important;

  height: 58px !important;

  &:disabled {
    background: var(--color-gray-100) !important;
    pointer-events: none;
    cursor: not-allowed;

    &::placeholder {
      color: var(--color-gray-400) !important;
    }
  }
  &::placeholder {
    color: var(--color-gray-500) !important;
  }

  .ng-select-container {
    height: 58px !important;
  }

  .ng-value-label,
  .ng-value {
    color: var(--color-black) !important;
  }

  .ng-input {
    top: 12px !important;
  }
}
