.c-spinner {
  border: 2px solid transparent; /* Light grey */
  border-top: 2px solid white; /* Blue */
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
