$size-base: 1rem;
$size-xs: $size-base * 0.25; // 4px
$size-sm: $size-base * 0.5; // 8px
$size-md: $size-base * 1; // 16px
$size-lg: $size-base * 1.5; // 24px
$size-xl: $size-base * 2; // 32px
$size-2xl: $size-base * 2.5; // 40px
$size-3xl: $size-base * 3; // 48px

$break-sm: 576px;
$break-md: 768px;
$break-lg: 1024px;
$break-xl: 1280px;
$break-2xl: 1600px;
