@import '../node_modules/ngx-toastr/toastr.css';
@import '../sizing.scss';
@import '../variables.scss';

.ngx-toastr {
  width: auto !important;
  max-width: calc($size-base * 21) !important;
  border-radius: $size-sm !important;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  padding: $size-lg !important;
  margin-bottom: $size-sm !important;
  background-position: 24px center !important;

  @media screen and (min-width: $break-md) {
    max-width: calc($size-base * 26) !important;
  }
}

.toast-container {
  padding-left: $size-lg !important;
  padding-right: $size-lg !important;
}

.toast-title {
  display: none !important;
}

.toast-message {
  @extend .t-text--lg;
  @extend .u-font-weight--medium;

  word-wrap: break-word;
  line-break: normal;
  overflow: auto;
  margin-left: $size-xl;
}

.toast-close-button {
  font-size: $size-lg !important;
  position: unset !important;
  margin-left: auto !important;

  &:hover {
    opacity: 1 !important;
  }
}

.toast-top-center {
  top: $size-2xl;
}

.toast-success {
  background-image: url('/assets/images/icons/toast/checkmark-circle.svg') !important;
  background-color: var(--support-green-1) !important;

  .toast-message {
    color: var(--support-green-3);
  }

  .toast-close-button {
    color: var(--support-green-3) !important;
  }
}

.toast-info {
  background-image: url('/assets/images/icons/toast/alert-circle.svg') !important;
  background-color: var(--color-blue-300) !important;

  .toast-message {
    color: var(--color-blue-600);
  }

  .toast-close-button {
    color: var(--color-blue-600) !important;
  }
}

.toast-error {
  background-image: url('/assets/images/icons/toast/close-circle.svg') !important;
  background-color: var(--support-red-1) !important;

  .toast-message {
    color: var(--support-red-3);
  }

  .toast-close-button {
    color: var(--support-red-3) !important;
  }
}

.toast-warning {
  background-image: url('/assets/images/icons/toast/warning.svg') !important;
  background-color: var(--color-orange-100) !important;

  .toast-message {
    color: var(--color-orange-300);
  }

  .toast-close-button {
    color: var(--color-orange-300) !important;
  }
}
