@import '../sizing.scss';

.c-page-header {
  gap: $size-sm;
  display: flex;
  align-items: center;
  justify-content: space-between;

  min-height: 58px;

  padding: $size-md $size-md 0;

  &__filter {
    flex: 1;
    gap: $size-sm;
    max-height: 48px;
    padding: $size-md 12px;
    color: var(--color-blue-500);
    background: transparent;
    border: 1px solid var(--color-blue-500);
    border-radius: $size-sm;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__icon {
    font-size: $size-lg;
    color: inherit;
  }

  &__title {
    font-size: $size-lg;
    line-height: $size-md;
    color: var(--color-black) !important;
    font-weight: 400;
    margin-bottom: 0;
  }

  &__divisor {
    display: flex;
    flex-direction: row;
    gap: $size-sm;
    align-items: center;
    margin-bottom: $size-lg;

    &__line {
      height: 1px;
      background: var(--color-gray-400);
      flex: 1;
    }

    &__text {
      font-size: 1.125 * $size-base;
      color: var(--color-gray-500);
    }

    &__title,
    &__subtitle {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: $size-lg;
    }

    &__title {
      font-size: 1.5 * $size-base;
      color: var(--color-black);
    }

    &__subtitle {
      font-size: $size-base;
      color: var(--color-gray-600);
    }
  }
}

@media screen and (min-width: $break-md) {
  .c-page-header {
    padding: 0px;
    &__filter {
      max-height: unset;
      padding: unset;
      color: var(--color-black);
      border: transparent;
      border-radius: unset;
    }

    &__icon {
      display: none;
    }

    &__title {
      font-size: $size-lg;
      line-height: 2.25rem;
      color: var(--color-black) !important;
      font-weight: 400;
    }
  }
}
