@import 'sizing.scss';

@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

p,
span,
a,
strong {
  font-family: 'Inter', sans-serif !important;
}

.t-base-font {
  font-family:
    'Inter',
    'Nunito Sans',
    -apple-system,
    '.SFNSText-Regular',
    'San Francisco',
    BlinkMacSystemFont,
    'Segoe UI',
    'Helvetica Neue',
    Helvetica,
    Arial,
    sans-serif !important;
  color: var(--font-primary);
  font-weight: 400;
  white-space: pre-line;
}

.t-display--sm {
  @extend .t-base-font;

  font-size: 1.25rem !important; // 20px
  line-height: 1.75rem !important; // 28px
  letter-spacing: -0.02em !important;
}

.t-display--xs {
  @extend .t-base-font;

  font-size: 1.125rem !important; // 18px
  line-height: 1.5rem !important; // 24px
  letter-spacing: -0.02em !important;
}

.t-text--2xl {
  @extend .t-base-font;

  font-size: 1.5rem !important; // 24px
  line-height: 2.25rem !important; // 36px
}

.t-text--xl {
  @extend .t-base-font;

  font-size: 1.25rem !important; // 20px
  line-height: 1.875rem !important; // 30px
}

.t-text--lg {
  @extend .t-base-font;

  font-size: 1.125rem !important; // 18px
  line-height: 1.75rem !important; // 28px
}

.t-text--md {
  @extend .t-base-font;

  font-size: 1rem !important; // 16px
  line-height: 1.5rem !important; // 24px
}

.t-text--sm {
  @extend .t-base-font;

  font-size: 0.875rem !important; // 14px
  line-height: 1.25rem !important; // 20px
}

.t-text--xs {
  @extend .t-base-font;

  font-size: 0.75rem !important; // 12px
  line-height: 1.125rem !important; // 18px
}
