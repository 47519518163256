@import '../sizing.scss';
@import '../variables.scss';

.c-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  align-self: stretch;
  padding: $size-lg;
  background-color: var(--color-white);
  border-radius: $size-lg;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);

  &--blue {
    background-color: var(--color-blue-100);
    border: 1px solid var(--color-blue-400);
  }

  &--disabled {
    background-color: var(--color-gray-100);
  }

  &__text {
    @extend .t-text--sm;
    color: var(--color-gray-600);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: $size-sm;
    line-height: normal !important;

    &--title {
      font-weight: 600;
      font-size: $size-md;
      color: var(--color-blue-700);
    }

    &--subtitle {
      font-weight: 500;
      color: var(--color-blue-600);
    }

    &--disabled {
      color: var(--color-gray-500);
    }

    &--link {
      color: var(--test-blue-5);
      text-decoration-line: underline;

      &-disabled {
        color: var(--test-blue-3);
      }
    }
  }

  &_content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: $size-md;
    align-self: stretch;

    &_info {
      display: flex;
      flex-direction: row;
      gap: $size-md;

      &__img {
        border-radius: 50%;
        height: $size-2xl;
        width: $size-2xl;
        object-fit: cover;

        &--disabled {
          filter: grayscale(1);
          opacity: 0.5;
        }
      }
    }
  }

  &_footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;

    &_tag-container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: $size-lg;
      align-self: stretch;
    }

    &_actions {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      @media screen and (min-width: 480px) {
        gap: $size-sm;
      }
    }

    &__tag {
      @extend .t-text--sm;
      display: flex;
      flex: 1;
      padding: $size-sm;
      justify-content: center;
      align-items: center;
      gap: $size-xs;
      align-self: stretch;
      border: none;
      border-radius: $size-sm;
      background: var(--color-blue-200);
      position: relative;
      color: var(--color-blue-500);
      text-wrap: nowrap;

      &--disabled {
        background: var(--color-gray-200);
        color: var(--color-gray-500);
      }
    }
  }
}
