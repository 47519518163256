.sidebar {
  padding: 4px 20px 100px 20px;

  .btn-toggle-offcanvas {
    position: absolute;
    width: 30px;
    left: unset !important;
    right: 8px;
    top: 0px !important;
    font-size: 18px !important;
  }

  .user-account {
    position: fixed;
    bottom: 0;
    margin: 0;
    padding: 12px 0px;
    background-color: var(--color-white);
    border-top: 1px solid rgb(228, 228, 231) !important;
    width: 229px;
  }
}

.sidebar-nav {
  .metismenu {
    a {
      display: flex;
      align-items: center;
      color: var(--color-text-dark) !important;
      i {
        color: var(--color-text-dark) !important;
        display: block;
        position: relative;
        top: unset;
        right: unset;
        margin-right: 12px;
      }

      &.active {
        color: var(--color-blue-500) !important;
        background-color: var(--color-blue-100);
        border-radius: 8px;
        i {
          color: var(--color-blue-500) !important;
        }
      }
    }
  }
}

.navbar {
  box-shadow: unset !important;
  border-bottom: 1px solid rgb(228, 228, 231) !important;
  background: var(--color-white);
}
