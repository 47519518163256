.c-swal-modal {
  border-radius: 24px !important;

  .swal2-icon {
    width: 52px !important;
    height: 52px !important;
  }

  .swal2-icon-content {
    font-size: 38px !important;
  }

  .swal2-cancel {
    color: var(--color-black) !important;
  }

  .swal2-actions {
    gap: 22px !important;
  }

  button {
    font-size: 18px !important;
    font-weight: 400 !important;
    padding: 16px 48px !important;
    border-radius: 8px !important;
  }

  @media screen and (max-width: $break-md) {
    .swal2-actions {
      gap: 8px !important;
    }
    button {
      padding: 16px 32px !important;
    }
  }
}

.c-modal {
  position: fixed;
  inset: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $size-lg;
  z-index: 100;

  &__overlay {
    position: fixed;
    inset: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 91;
  }

  &_custom {
    padding: $size-md;
    max-width: 662px !important;
    justify-content: center;

    &--sm {
      max-width: 500px !important;
    }

    @media (min-width: 576px) {
      &.modal-sm {
        max-width: 300px !important;
      }
    }

    @media (min-width: 940px) {
      &.modal-lg,
      &.modal-xl {
        max-width: 832px !important;
      }
    }

    .modal-content {
      border: $size-lg solid white;
      border-radius: calc($size-base * 1.625);
      box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.1);

      @media (min-width: 576px) {
        border: $size-xl solid white;
      }
    }
  }

  &_body {
    max-height: 100%;
    width: 100%;
    max-width: 630px;
    position: relative;
    align-self: unset !important;
    height: auto !important;
    z-index: 92;
    background: var(--color-white);

    &--sm {
      max-width: 450px;

      & > * {
        padding-inline: $size-lg !important;
      }
    }

    &--md {
      max-width: 630px;
    }

    &--lg {
      max-width: 900px;
    }
  }

  &_content {
    display: flex;
    flex-direction: column;
    padding-bottom: $size-lg;
  }

  &_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: $size-lg;
    padding-bottom: $size-lg;
    position: sticky;
    top: 0;
    background-color: var(--color-white);
    z-index: 93;

    &__title {
      font-size: 1.125 * $size-base;
      font-weight: 400;
      color: var(--color-black);
      line-height: normal;
      align-self: center;
    }

    &__close {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      width: $size-lg;
      height: $size-lg;
      color: var(--color-blue-500);
      border: 1px solid var(--color-blue-500);
      border-radius: 100px;

      &-icon {
        width: $size-md;
        height: $size-md;
      }
    }

    // Use this class in a wrapper for your ion-icon
    &_icon {
      display: flex;
      justify-content: center;
      flex: 1;

      & > ion-icon {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        padding: 9px;
        color: var(--color-white);
      }
    }
  }

  &_footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: $size-lg;
    position: sticky;
    bottom: 0;
    background-color: var(--color-white);
    z-index: 93;

    &__divisor {
      height: 1px;
      min-height: 1px;
      background-color: var(--color-gray-400);
    }

    &_buttons {
      display: flex;
      flex-direction: row;
      gap: $size-lg;

      & > * {
        flex: 1;
      }
    }
  }

  @media screen and (min-width: $break-sm) {
    &_header {
      padding-bottom: $size-xl;
      gap: $size-xl;

      &__title {
        font-size: $size-lg;
        line-height: 150%;
      }

      &__close {
        border-radius: $size-sm;
        height: $size-3xl;
        width: $size-3xl;
        padding: $size-md 0.75 * $size-md;

        &-icon {
          width: $size-lg;
          height: $size-lg;
        }
      }

      &_icon > ion-icon {
        width: $size-2xl;
        height: $size-2xl;
        padding: 12px;
      }
    }

    &_content {
      padding-bottom: $size-xl;
      gap: $size-xl;
    }

    &_footer {
      gap: $size-xl;

      &_buttons {
        gap: $size-xl;
      }
    }
  }
}
